import axiosInstance from './axios';
import HttpApi from './endpoints';
/**
 * Fetches the details of a specific feedback item.
 *
 * @param id - The ID of the feedback item to fetch.
 * @returns A promise that resolves to an ApiResponse containing FeedbackItem data.
 */
export const getFeedbackDetail = (id) => {
    return axiosInstance.get(HttpApi.feedbackDetail.replace('%s', id.toString()));
};
/**
 * Fetches the list of feedback items.
 *
 * @param params - The parameters for fetching the feedback list.
 * @returns A promise that resolves to an ApiResponse containing FeedbackEntity data.
 */
export const getFeedbackList = (params) => {
    return axiosInstance.post(HttpApi.getFeedbackList, params);
};
/**
 * Submits feedback to the server.
 *
 * @param data - The feedback data to submit.
 * @param files - An array of File objects to upload with the feedback.
 * @returns A promise that resolves to an ApiResponse.
 */
export const submitFeedback = (data, files) => {
    const formData = new FormData();
    formData.append('issueDesc', data.issueDesc);
    if (data.contactInfo) {
        formData.append('contactInfo', data.contactInfo);
    }
    files.forEach((file, index) => {
        formData.append(`fileList[${index}]`, file);
    });
    return axiosInstance.post(HttpApi.feedback, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
};
