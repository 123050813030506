import { defineComponent, reactive, computed, onMounted, ref } from 'vue';
import { Message } from '@arco-design/web-vue';
import { useI18n } from 'vue-i18n';
import { IconDown, IconEmail, IconLock } from '@arco-design/web-vue/es/icon';
import { useStore } from '@/store/userStore';
import { getDeviceInfo } from '@/utils/device';
import { useRoute, useRouter } from 'vue-router';
export default defineComponent({
    name: 'LoginPage',
    components: {
        IconDown,
        IconEmail,
        IconLock,
    },
    setup() {
        const { locale } = useI18n();
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const form = reactive({
            email: '',
            password: '',
        });
        const currentLanguage = computed(() => locale.value === 'zh' ? '简体中文' : 'English');
        const changeLanguage = (value) => {
            locale.value = value;
        };
        const isLoading = ref(false);
        /**
         * Handles the form submission for login.
         */
        const handleSubmit = async () => {
            try {
                if (!form.email || !form.password) {
                    Message.warning('请输入邮箱和密码');
                    return;
                }
                isLoading.value = true; // 开始加载
                const deviceInfo = await getDeviceInfo();
                const loginData = {
                    userName: form.email,
                    password: form.password,
                    loginType: 'UP',
                    ...deviceInfo,
                };
                const response = await store.dispatch('auth/login', loginData);
                if (response.code === 0) {
                    localStorage.setItem('auth_token', response.data.token);
                    // 获取用户信息
                    const userInfoResponse = await store.dispatch('auth/getUserInfo');
                    if (userInfoResponse.code === 0) {
                        Message.success('登录成功');
                        router.push('/main');
                    }
                    else {
                        Message.error('获取用户信息失败');
                    }
                }
                else {
                    Message.error(response.msg || '登录失败');
                }
            }
            catch (error) {
                console.error('Login failed:', error);
                Message.error('登录失败');
            }
            finally {
                isLoading.value = false; // 结束加载
            }
        };
        onMounted(() => {
            if (route.query.email) {
                form.email = route.query.email;
            }
        });
        return {
            form,
            handleSubmit,
            currentLanguage,
            changeLanguage,
            isLoading, // 暴露 isLoading 给模板
        };
    },
});
