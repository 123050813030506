import { createStore } from 'vuex';
import { authModule } from './auth';
import fileModule from './fileStore';
import fileDetailModule from './fileDetailStore';
import feedbackModule from './feedbackStore';
const store = createStore({
    modules: {
        auth: authModule,
        fileStore: fileModule,
        fileDetail: fileDetailModule,
        feedbackStore: feedbackModule,
    },
});
export default store;
