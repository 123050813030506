import { getFeedbackList, submitFeedback } from '@/api/feedback';
import { Message } from '@arco-design/web-vue';
const feedbackModule = {
    namespaced: true,
    state: () => ({
        feedbacks: [],
        totalFeedbacks: 0,
        currentPage: 1,
        pageSize: 10,
        filterStatus: '',
        searchKeyword: '',
    }),
    mutations: {
        SET_FEEDBACKS(state, feedbacks) {
            state.feedbacks = feedbacks;
        },
        SET_TOTAL_FEEDBACKS(state, total) {
            state.totalFeedbacks = total;
        },
        SET_CURRENT_PAGE(state, page) {
            state.currentPage = page;
        },
        SET_FILTER_STATUS(state, status) {
            state.filterStatus = status;
        },
        SET_SEARCH_KEYWORD(state, keyword) {
            state.searchKeyword = keyword;
        },
    },
    actions: {
        async fetchFeedbacks({ commit }, payload) {
            try {
                const response = await getFeedbackList(payload);
                commit('SET_FEEDBACKS', response.data.dataList);
                commit('SET_TOTAL_FEEDBACKS', response.data.pageParam?.totalElements || 0);
                return response;
            }
            catch (error) {
                console.error('Error fetching feedbacks:', error);
                throw error;
            }
        },
        async submitFeedback({ dispatch }, payload) {
            try {
                await submitFeedback(payload, []);
                Message.success('反馈提交成功');
                dispatch('fetchFeedbacks');
            }
            catch (error) {
                console.error('Error submitting feedback:', error);
                Message.error('提交反馈失败，请稍后重试。');
            }
        },
        setCurrentPage({ commit, dispatch }, page) {
            commit('SET_CURRENT_PAGE', page);
            dispatch('fetchFeedbacks');
        },
        setFilterStatus({ commit, dispatch }, status) {
            commit('SET_FILTER_STATUS', status);
            commit('SET_CURRENT_PAGE', 1);
            dispatch('fetchFeedbacks');
        },
        setSearchKeyword({ commit, dispatch }, keyword) {
            commit('SET_SEARCH_KEYWORD', keyword);
            commit('SET_CURRENT_PAGE', 1);
            dispatch('fetchFeedbacks');
        },
    },
    getters: {
        filteredFeedbacks: (state) => state.feedbacks,
    },
};
export default feedbackModule;
