import { computed, defineComponent, onMounted, ref, onUnmounted, } from 'vue';
import { useStore } from 'vuex';
import { Message } from '@arco-design/web-vue';
import { IconMore } from '@arco-design/web-vue/es/icon';
import { useRouter } from 'vue-router';
import { getFileIcon, getStatusColor } from '@/utils/utils';
export default defineComponent({
    name: 'FileManager',
    components: { IconMore },
    setup() {
        const store = useStore();
        const router = useRouter();
        const loading = ref(false);
        const columns = [
            {
                title: '文件名称',
                dataIndex: 'name',
                slotName: 'name',
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                render: ({ record }) => {
                    const date = new Date(record.createTime || 0);
                    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
                },
                width: 150,
            },
            {
                title: '保留时长',
                dataIndex: 'expiredTime',
                render: ({ record }) => {
                    if (!record.expiredTime || !record.createTime)
                        return '-';
                    const days = Math.floor((record.expiredTime - record.createTime) / (24 * 60 * 60 * 1000));
                    return `${days} 天`;
                },
                width: 150,
            },
            { title: '操作', dataIndex: 'actions', slotName: 'actions', width: 80 },
        ];
        const fetchFiles = async (page, size) => {
            console.log('Fetching file manager files...');
            loading.value = true;
            try {
                const params = {
                    pageNum: page,
                    pageSize: size,
                };
                console.log('Fetch params:', params);
                await store.dispatch('fileStore/fetchFileManagerFiles', params);
                console.log('File manager files fetched successfully');
            }
            catch (error) {
                console.error('Error fetching file manager files:', error);
                Message.error('获取文件列表失败，请稍后重试。');
            }
            finally {
                loading.value = false;
            }
        };
        const fileManagerFiles = computed(() => store.getters['fileStore/fileManagerFiles']);
        const fileManagerTotalFiles = computed(() => store.getters['fileStore/fileManagerTotalFiles']);
        const fileManagerCurrentPage = computed(() => store.getters['fileStore/fileManagerCurrentPage']);
        const fileManagerPageSize = computed(() => store.getters['fileStore/fileManagerPageSize']);
        const handlePageChange = (page) => {
            fetchFiles(page, fileManagerPageSize.value);
        };
        const handleAction = (action, record) => {
            switch (action) {
                case 'rename':
                    // Implement rename logic
                    Message.info(`重命名文件: ${record.name}`);
                    break;
                case 'move':
                    // Implement move logic
                    Message.info(`移动文件: ${record.name}`);
                    break;
                case 'delete':
                    store.dispatch('fileStore/deleteFile', {
                        type: record.type,
                        fileId: record.id,
                    });
                    break;
                default:
                    Message.info(`未知操作: ${action}`);
            }
        };
        const handleItemClick = (record) => {
            if (record.type === 'folder') {
                router.push({
                    name: 'SubFolder',
                    params: { id: record.id?.toString() || '' },
                });
            }
            else {
                console.log('Navigating to FileDetail with query:', {
                    code: record.code,
                    translatedFileCode: record.translatedFileCode,
                    name: record.name,
                    status: record.status,
                    duration: record.duration?.toString(),
                    createTime: record.createTime?.toString(),
                    expiredTime: record.expiredTime?.toString(),
                    previewUrl: record.previewUrl,
                });
                router.push({
                    name: 'FileDetail',
                    query: {
                        code: record.code || '',
                        translatedFileCode: record.translatedFileCode || '',
                        name: record.name || '',
                        status: record.status || '',
                        duration: record.duration?.toString() || '',
                        createTime: record.createTime?.toString() || '',
                        expiredTime: record.expiredTime?.toString() || '',
                        previewUrl: record.previewUrl || '',
                    },
                });
            }
        };
        const refreshFileList = () => {
            console.log('Refreshing file manager list');
            fetchFiles(fileManagerCurrentPage.value, fileManagerPageSize.value);
        };
        onMounted(() => {
            console.log('FileManager mounted');
            fetchFiles(fileManagerCurrentPage.value, fileManagerPageSize.value);
            window.addEventListener('refreshFileList', refreshFileList);
        });
        onUnmounted(() => {
            window.removeEventListener('refreshFileList', refreshFileList);
        });
        return {
            columns,
            loading,
            fileManagerFiles,
            fileManagerTotalFiles,
            fileManagerCurrentPage,
            fileManagerPageSize,
            handlePageChange,
            handleAction,
            handleItemClick,
            getFileIcon,
            getStatusColor,
        };
    },
});
